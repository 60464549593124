/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/global.css"

import React, { useEffect } from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import { StateMachineProvider, createStore } from 'little-state-machine'
import liff from '@line/liff'

import { ApolloClient, InMemoryCache } from '@apollo/client'

const client = new ApolloClient({
  uri: 'http://localhost:3030/graphql',
  cache: new InMemoryCache({
    addTypename: false,
  }),
})

createStore({
  data: {}
})

liff.init({ liffId: '1553392709-5pDY7dab' })

const wrapRootElement = ({ element }) => {
  return (
    <StateMachineProvider>
      <ApolloProvider client={client}>
        {element}
      </ApolloProvider>
    </StateMachineProvider>
  )
}

const onInitialClientRender = () => {
  setTimeout(function() {
      document.getElementById("___loader").style.display = "none"
  }, 1000)
}

export {
  wrapRootElement,
  onInitialClientRender
}